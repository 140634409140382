<template>
  <v-layout
    style="
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -o-user-select: none;
      padding: 0;
    "
    fluid
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
  >
    <v-data-table
      :items="Mode == 0 ? lines_data : edit_data"
      item-key="id"
      single-select
      class="elevation-1 add_table"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      :height="tableheight / 3"
      dense
    >
      <!-- :height="tableheight - toolbarHeight -12" -->
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->

      <template v-slot:item="{ item }">
        <!-- @contextmenu="popup_menu($event, item)" -->
        <tr
          @touchstart="touch = true"
          @click="row_click($event, item)"
          @contextmenu="popup_menu($event, item)"
          :class="{ 'deep-purple lighten-4': item.id === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->

          <td width="6%" class="id_column pl-1 pr-0">{{ item.id }}.</td>
          <td
            width="10%"
            class="bettype_column border_class pl-1 pr-0"
            :class="getBetTypeColor(item.BetTypeID)"
          >
            {{ getBetTypeName(item.BetTypeID) }}
          </td>
          <td class="betnumber_column pl-1 pr-0">{{ item.Number }}</td>
          <td class="equal_column pa-0">=</td>
          <td align="end" class="price1_column pl-1 pr-1">
            {{ add_comma(getPrice1(item.PriceText)) }}
          </td>
          <td
            class="sign_column pa-0"
            :class="getPriceSignColor(item.PriceText)"
          >
            {{ getPriceSign(item.PriceText) }}
          </td>
          <td align="end" class="price2_column border_class pl-1 pr-1">
            {{ add_comma(getPrice2(item.PriceText)) }}
          </td>
          <td
            align="end"
            class="status_column pl-1 pr-1"
            :class="getStatusColor(item.Status)"
          >
            {{ getStatusText(item.Status) }}
          </td>
        </tr>
      </template>
      <template slot="body.append">
        <!-- v-if="!isTouchScreendevice()" -->
        <tr v-if="Mode == 1">
          <td colspan="8" align="center" class="red--text text--darken-2">
            แก้ไขเป็น
          </td>
        </tr>
        <tr>
          <td width="6%" class="id_column_new pl-1 pr-0">
            {{ Input.ShowLine }}.
          </td>
          <td
            width="10%"
            class="bettype_column border_class pl-1 pr-0"
            :class="getBetTypeColor(Input.BtType)"
          >
            {{ getBetTypeName(Input.BtType) }}
          </td>
          <td class="betnumber_column pl-1 pr-0" :class="getNumKeyColor()">
            {{ Input.Num }}
          </td>
          <td class="equal_column pa-0">=</td>
          <td align="end" class="price1_column pl-1 pr-1">{{ Input.P1 }}</td>
          <td class="sign_column pa-0" :class="getSignColor(Input.Sign)">
            {{ Input.Sign }}
          </td>
          <td
            class="price2_column border_class pl-1 pr-0"
            :class="getP2KeyColor()"
          >
            {{ Input.P2 }}
          </td>
          <td class="status_column pl-1 pr-0" :class="getP2KeyColor()"></td>
        </tr>
        <tr v-if="Mode == 1">
          <td colspan="8" align="center" class="red--text text--darken-2"></td>
        </tr>
        <tr v-if="Mode == 1">
          <td colspan="8" align="center" class="red--text text--darken-2">
            <v-btn
              text
              color="red"
              style="font-weight: bold"
              @click="CancelEdit()"
              >ยกเลิก
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="no-data">
        <td></td>
      </template>
    </v-data-table>

    <v-card :height="tableheight - tableheight / 3" class="blue-grey lighten-4">
      <v-card-subtitle
        class="my-1 py-2 text-center"
        style="font-size: 4.2vw; font-weight: bold"
        >{{ Process_Text }}</v-card-subtitle
      >
      <!-- <v-divider></v-divider>
      <v-card-title class="my-0 py-0 px-1 blue-grey lighten-4"
        ><span class="span_betline text-center"></span
        ><span class="span_bettype text-right">ล่าง</span
        ><span class="span_number text-right">12</span
        ><span class="span_equal text-center">=</span
        ><span class="span_price1 text-right">100</span
        ><span class="span_sign text-center">x</span
        ><span class="span_price2 text-right">100</span></v-card-title
      > -->
      <v-card-text class="keyboard pa-1 blue-grey lighten-4">
        <div>
          <v-btn class="btn" @click="OnShowBetType()">อื่นๆ</v-btn>
        </div>
        <div>
          <v-btn
            class="btn"
            :class="getTopColor()"
            @click="ChangeInputBetType('1')"
            >บน</v-btn
          >
        </div>
        <div>
          <v-btn
            class="btn"
            :class="getDownColor()"
            @click="ChangeInputBetType('2')"
            >ล่าง</v-btn
          >
        </div>
        <div class="del">
          <v-btn class="btn" @touchstart="OnKeyDel()">
            <v-icon large color="red lighten-2">
              mdi-arrow-left-bold
            </v-icon></v-btn
          >
        </div>
        <div>
          <v-btn class="btn_number" @touchstart="OnKeyNumber(7)" dark>7</v-btn>
        </div>
        <div>
          <v-btn class="btn_number" @touchstart="OnKeyNumber(8)" dark>8</v-btn>
        </div>
        <div>
          <v-btn class="btn_number" @touchstart="OnKeyNumber(9)" dark>9</v-btn>
        </div>
        <div class="multiply">
          <v-btn
            class="btn_multiply"
            color="green lighten-4"
            @touchstart="OnKeySign('x')"
          >
            <v-icon> mdi-close </v-icon></v-btn
          >
        </div>
        <div>
          <v-btn class="btn_number" @touchstart="OnKeyNumber(4)" dark>4</v-btn>
        </div>
        <div>
          <v-btn class="btn_number" @touchstart="OnKeyNumber(5)" dark>5</v-btn>
        </div>
        <div>
          <v-btn class="btn_number" @touchstart="OnKeyNumber(6)" dark>6</v-btn>
        </div>
        <div>
          <v-btn class="btn_number" @touchstart="OnKeyNumber(1)" dark>1</v-btn>
        </div>
        <div>
          <v-btn class="btn_number" @touchstart="OnKeyNumber(2)" dark>2</v-btn>
        </div>
        <div>
          <v-btn class="btn_number" @touchstart="OnKeyNumber(3)" dark>3</v-btn>
        </div>
        <div class="zero">
          <v-btn class="btn_number" @touchstart="OnKeyNumber(0)" dark>0</v-btn>
        </div>
        <div>
          <v-btn
            class="btnSwap"
            color="light-blue lighten-4"
            @touchstart="OnKeySign('.')"
            >ก.</v-btn
          >
        </div>
        <div class="equal">
          <v-btn
            class="btn_2"
            color="red lighten-4"
            @touchstart="OnKeyEqual('=')"
            >=</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
    <BetTypeDlg ref="BetTypeDlg" @OnChangeBetType="ChangeInputBetType" />
    <PopupMenu ref="PopupMenu" @del="OnDeleteLine" @edit="SetEditMode" />
  </v-layout>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import betFunctionMixin from "@/mixins/betFunctionMixin";

import BetTypeDlg from "@/components/Main/BetTypeDlg";
import PopupMenu from "@/components/Pages/KeyPage/Popup_Menu";

export default {
  name: "KeyPage_Table",

  components: {
    BetTypeDlg,
    PopupMenu,
  },

  mixins: [globalFunctionMixin, betFunctionMixin],
  props: {
    tableheight: {
      type: Number,
      default: 500, //default v-data-table footer height
    },
    CusID: {
      type: String,
      default: "",
    },
    PageNumber: {
      type: Number,
      default: 0,
    },
    UpdateDT: {
      type: String,
      default: "",
    },
    EditLine: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.$nextTick(() => {
      //   console.log("mounted");
      //   console.log(this.EditLine);

      this.OnUpdateTable();
      //   this.table_focus();
    });
  },
  computed: {
    lines_data() {
      //   console.log("update lines_data");
      return this.$store.getters["page/LineList"];
    },
  },

  methods: {
    // SYSTEM
    //FOR TABLE
    table_focus() {
      var container = document.querySelector(
        ".add_table .v-data-table__wrapper"
      );
      var scrollHeight = container.scrollHeight;
      //   console.log(container.scrollTop);
      container.scrollTop = scrollHeight;
    },
    OnUpdateTable() {
      console.log("OnUpdateTable");
      //this.$store.getters["keypage/LineList"];

      if (this.EditLine != "") {
        this.SetEditMode(this.EditLine);
      } else {
        this.SetLastLine(this.$store.getters["page/LineList"].slice(-1)[0]);
        this.table_focus();
      }
    },
    async OnSendLine(BtType, Num, P1, Sign, P2) {
      console.log("OnSendLine");
      let parameter = {
        to: "KeyPage",
        method: "add_line",
        PeriodDT: this.PeriodDT,
        LineID: this.LineID,
        AgentID: this.AgentID,
        CusID: this.CusID,
        PageNumber: parseInt(this.PageNumber),
        BetTypeID: BtType,
        BetNumber: Num,
        PriceText: P1 + Sign + P2,
        LastedUpdate: this.UpdateDT,
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          this.InputClear();
          this.SetLastLine(response.RecentBetLine);

          if (response.IsReload == true) {
            //emit reload
          }

          //clear
          this.Sending = false;
        },
        (error) => {
          //   switch (error) {
          //     case "PIN_INCORRECT":
          //       this.$refs.pincodeInput.resetPincode();
          //       this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
          //       break;
          //     default:
          //       alert(error);
          //       break;
          //   }
          this.Sending = false;
        }
      );
    },

    async OnEditLine(OldLineNumber, BtType, Num, P1, Sign, P2) {
      console.log("OnSendLine");
      let parameter = {
        to: "KeyPage",
        method: "edit_line",
        PeriodDT: this.PeriodDT,
        LineID: this.LineID,
        AgentID: this.AgentID,
        CusID: this.CusID,
        PageNumber: parseInt(this.PageNumber),
        BetTypeID: BtType,
        BetNumber: Num,
        PriceText: P1 + Sign + P2,
        OldLineNumber: OldLineNumber,
        LastedUpdate: this.UpdateDT,
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          this.InputClear();
          this.SetInsertMode();
          //   this.SetLastLine(response.RecentBetLine);

          if (response.IsReload == true) {
            //emit reload
          }
          this.CancelEdit();

          //clear
          this.Sending = false;
        },
        (error) => {
          //   switch (error) {
          //     case "PIN_INCORRECT":
          //       this.$refs.pincodeInput.resetPincode();
          //       this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
          //       break;
          //     default:
          //       alert(error);
          //       break;
          //   }
          this.Sending = false;
        }
      );
    },

    CancelEdit() {
      if (this.EditLine != "") {
        this.$router.push({
          path: "Page",
          query: {
            PageNumber: this.PageNumber,
            CusID: this.CusID,
          },
        });
      } else {
        this.InputClear();
        this.SetInsertMode();
      }
    },

    SetLastLine(p_LastLine) {
      console.log("p_LastLine");
      console.log(p_LastLine);
      if (p_LastLine == null) {
        this.ShowProcess("process", "กำลังเพิ่มบรรทัดที่ 1");
        return;
      }
      //   console.log("SetLastLine");
      //   console.log(p_LastLine);
      this.Input.BtType = p_LastLine.BetTypeID;
      //   console.log("this.Input.BtType");
      //   console.log(this.Input.BtType);
      if (this.Mode == 0) {
        this.Input.ShowLine = this.$store.getters["page/LineList"].length + 1;
        this.LastLine.ShowLine = this.$store.getters["page/LineList"].length;
        this.ShowProcess(
          "process",
          "กำลัง เพิ่ม บรรทัดที่ " +
            (parseInt(this.$store.getters["page/LineList"].length) + 1)
        );
      } else if (this.Mode == 1) {
        this.Input.ShowLine = p_LastLine.id;
        this.LastLine.ShowLine = p_LastLine.id;
        this.ShowProcess("process", "กำลัง แก้ไข บรรทัดที่ " + p_LastLine.id);
      }

      this.LastLine.BtType = p_LastLine.BetTypeID;
      this.LastLine.Num = p_LastLine.Number;

      let PriceText = p_LastLine.PriceText;

      if (PriceText.indexOf(".") > 0) {
        const PriceArray = PriceText.split(".");
        this.LastLine.P1 = PriceArray[0];
        this.LastLine.Sign = "ก";
        this.LastLine.P2 = PriceArray[1];
      } else if (PriceText.indexOf("x") > 0) {
        const PriceArray = PriceText.split("x");
        this.LastLine.P1 = PriceArray[0];
        this.LastLine.Sign = "x";
        this.LastLine.P2 = PriceArray[1];
      } else {
        this.LastLine.P1 = "";
        this.LastLine.Sign = "";
        this.LastLine.P2 = PriceText;
      }
      //   this.$emit("OnChangeBetType", p_LastLine.BetTypeID);
    },
    async OnDeleteLine(LineNumber) {
      console.log("Delete:" + LineNumber);
      //   console.log("OnSendLine");
      let parameter = {
        to: "KeyPage",
        method: "del_line",
        PeriodDT: this.PeriodDT,
        LineID: this.LineID,
        AgentID: this.AgentID,
        CusID: this.CusID,
        PageNumber: parseInt(this.PageNumber),
        LineNumber: LineNumber,
        LastedUpdate: this.UpdateDT,
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          this.SetLastLine(this.$store.getters["page/LineList"].slice(-1)[0]);
        },
        (error) => {}
      );
    },
    //FOR EDIT
    SetEditMode(LineNumber) {
      this.Mode = 1;

      var container = document.querySelector(
        ".add_table .v-data-table__wrapper"
      );
      this.CurrentScrollTop = container.scrollTop;
      //container.scrollTop = scrollHeight;

      this.edit_data[0] = this.$store.getters["page/LineList"].find(
        (o) => o.LineNumber === LineNumber
      );
      this.SetLastLine(this.edit_data[0]);
    },

    SetInsertMode() {
      console.log("SetInsertMode");
      this.Mode = 0;
      this.SetLastLine(this.$store.getters["page/LineList"].slice(-1)[0]);

      setTimeout(() => {
        var container = document.querySelector(
          ".add_table .v-data-table__wrapper"
        );
        container.scrollTop = this.CurrentScrollTop;
      }, 500);
    },
    // FOR KEY
    OnKeyNumber(Number) {
      //   console.log("key:" + Number);
      window.navigator.vibrate(40);
      switch (this.Stage) {
        case 0:
          if (
            this.Input.Num.length < this.getMaxBetTypeLength(this.Input.BtType)
          )
            this.Input.Num += Number;
          break;
        case 1:
          if (!(Number == "0" && this.Input.P2 == "")) this.Input.P2 += Number;
          break;
      }
      this.table_focus();
    },
    OnKeyEqual() {
      //   console.log("OnKeyEqual");
      //   console.log("Stage");
      //   console.log(this.Stage);
      window.navigator.vibrate(40);
      this.table_focus();
      switch (this.Stage) {
        case 0:
          if (
            this.Input.BtType === this.LastLine.BtType &&
            this.Input.Num === this.LastLine.Num
          )
            if (this.Mode == 0) {
              if (!confirm("ต้องการแทงเลขซ้ำกับบรรทัดบนใช่หรือไม่?")) {
                return;
              }
            }

          if (
            this.Input.Num.length >=
              this.getMinBetTypeLength(this.Input.BtType) &&
            this.Input.Num.length <= this.getMaxBetTypeLength(this.Input.BtType)
          )
            this.Stage = 1;
          break;
        case 1:
          if (this.Sending == true) return;
          this.Sending = true;
          if (this.Input.P1.length == 0 && this.Input.P2.length == 0) {
            if (this.LastLine.Sign == "ก") {
              if (
                !this.canSwap(this.Input.BtType) ||
                this.Input.Num.length != 3
              ) {
                this.Sending = false;
                this.ShowProcess(
                  "error",
                  "ไม่อนุญาติให้ กลับเลข [" + this.Input.Num + "] ได้"
                );
                return;
              }

              if (this.isTripple(this.Input.Num)) {
                this.Sending = false;
                this.ShowProcess(
                  "error",
                  "ไม่อนุญาติให้ กลับ เลขตอง [" + this.Input.Num + "] ได้"
                );

                return;
              }
            } else if (this.LastLine.Sign == "x") {
              if (
                !(
                  this.canMultiply(this.Input.BtType) &&
                  this.Input.Num.length > 1
                )
              ) {
                this.Sending = false;
                return;
              }
              if (this.Input.Num.length == 2) {
                if (this.isDouble(this.Input.Num)) {
                  this.ShowProcess(
                    "error",
                    "ไม่อนุญาติให้ x เลขเบิ้ล [" + this.Input.Num + "] ได้"
                  );
                  this.Sending = false;
                  return;
                }
              } else if (this.Input.Num.length == 3) {
                if (this.isTripple(this.Input.Num)) {
                  this.ShowProcess(
                    "error",
                    "ไม่อนุญาติให้ x เลขตอง [" + this.Input.Num + "] ได้"
                  );
                  this.Sending = false;
                  return;
                }
              }
            }

            this.Input.P1 = this.LastLine.P1;
            this.Input.Sign = this.LastLine.Sign;
            this.Input.P2 = this.LastLine.P2;
            this.Sending = false;
            return;
          }

          if (this.Input.P2.length == 0 || this.Input.P2 == "0") {
            this.ShowProcess("error", "ราคาเป็นค่าว่าง หรือ 0 ไม่ได้");
            this.Sending = false;
            return;
          }

          if (this.Mode == 0) {
            this.OnSendLine(
              this.Input.BtType,
              this.Input.Num,
              this.Input.P1,
              this.Input.Sign,
              this.Input.P2
            );
          } else if (this.Mode == 1) {
            this.OnEditLine(
              this.edit_data[0].LineNumber,
              this.Input.BtType,
              this.Input.Num,
              this.Input.P1,
              this.Input.Sign,
              this.Input.P2
            );
          }

          break;
      }
    },
    OnKeySign(Sign) {
      window.navigator.vibrate(40);
      //   console.log(Sign);
      switch (Sign) {
        case "x":
          if (this.Input.P1 == "" && this.Input.P2 != "") {
            if (this.canMultiply(this.Input.BtType)) {
              // บน บนหน้า หมุน
              if (this.Input.Num.length == 3) {
                if (this.isTripple(this.Input.Num)) {
                  this.ShowProcess(
                    "error",
                    "ไม่อนุญาติให้ x เลขตอง [" + this.Input.Num + "] ได้."
                  );
                } else {
                  this.Input.Sign = "x";
                  this.Input.P1 = this.Input.P2;
                  this.Input.P2 = "";
                }
              } else if (this.Input.Num.length == 2) {
                if (this.isDouble(this.Input.Num)) {
                  this.ShowProcess(
                    "error",
                    "ไม่อนุญาติให้ x เลขเบิ้ล [" + this.Input.Num + "] ได้."
                  );
                } else {
                  this.Input.Sign = "x";
                  this.Input.P1 = this.Input.P2;
                  this.Input.P2 = "";
                }
              } else {
                this.ShowProcess(
                  "error",
                  "ต้องเป็นเลข 2 หรือ 3 ตัว ถึงจะคูณได้."
                );
              }
            } else {
              this.ShowProcess(
                "error",
                "ต้องเป็น 3บน 2บน 2ล่าง บน+ล่าง คู่หน้า หรือคู่ถ่าง ถึงจะคูณได้."
              );
            }
          }
          break;
        case ".":
          if (this.Input.P1 == "" && this.Input.P2 != "") {
            if (this.canSwap(this.Input.BtType)) {
              // บน บนหน้า หมุน
              if (this.isTripple(this.Input.Num)) {
                this.ShowProcess(
                  "error",
                  "ไม่อนุญาติให้ กลับ เลขตอง [" + this.Input.Num + "] ได้."
                );
              } else if (this.Input.Num.length == 3) {
                this.Input.Sign = "ก";
                this.Input.P1 = this.Input.P2;
                this.Input.P2 = "";
              } else {
                this.ShowProcess("error", "ต้องเป็นเลข 3 ตัว ถึงจะกลับได้.");
              }
            } else {
              this.ShowProcess(
                "error",
                "ต้องเป็น 3บน 3บนหน้า หรือหมุน ถึงจะกลับได้."
              );
            }
          }
          break;
      }
      this.table_focus();
    },
    OnShowBetType() {
      this.$refs.BetTypeDlg.Show(this.BtType);
    },
    ChangeInputBetType(BtType) {
      if (BtType == "" || BtType == "0") return;
      this.Input.BtType = BtType;
      this.Input.Num = "";
      this.Input.P1 = "";
      this.Input.Sign = "";
      this.Input.P2 = "";
      this.Stage = 0;
    },
    InputClear() {
      this.Input.Num = "";
      this.Input.P1 = "";
      this.Input.Sign = "";
      this.Input.P2 = "";
      this.Stage = 0;
      this.table_focus();
    },
    OnKeyDel() {
      window.navigator.vibrate(40);
      switch (this.Stage) {
        case 0:
          if (this.Input.Num.length > 0)
            this.Input.Num = this.Input.Num.slice(0, -1);
          break;
        case 1:
          if (this.Input.P2.length > 0) {
            this.Input.P2 = this.Input.P2.slice(0, -1);
          } else if (this.Input.Sign.length > 0) {
            this.Input.Sign = "";
            this.Input.P2 = this.Input.P1;
            this.Input.P1 = "";
          } else {
            this.Stage = 0;
          }
          break;
      }
      this.table_focus();
    },
    getPrice1(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return p_PriceText.split(".")[0];
      } else if (p_PriceText.indexOf("x") > 0) {
        return p_PriceText.split("x")[0];
      } else {
        return p_PriceText;
      }
    },
    getPriceSign(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return "ก";
      } else if (p_PriceText.indexOf("x") > 0) {
        return "x";
      } else {
        return "";
      }
    },
    getPrice2(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return p_PriceText.split(".")[1];
      } else if (p_PriceText.indexOf("x") > 0) {
        return p_PriceText.split("x")[1];
      } else {
        return "";
      }
    },
    getBetTypeColor(p_BetType) {
      switch (p_BetType) {
        case "1": //บน
        case "4": //พวง
        case "6": //บน/น";
        case "7": //บน/ก";
        case "8": //บน/ท";
        case "11": //บน/น";
        case "12": //บน/ถ";
        case "13": //ล.บ.";
        case "15": //19/บ";
        case "17": //ค.ต.";
          return "blue--text text--darken-3";

        case "2": // ล่าง
        case "5": //3 ล่าง
        case "9": //ล่าง/น";
        case "10": //ล่าง/ท";
        case "14": //ล.ล";
        case "16": //19/ล";
          return "red--text text--darken-1";

        case "3": //บ+ล
          return "grey--text text--darken-3";

        case "18": //3น.";
        case "19": //3น.";
        case "20": //3น/น";
        case "21": //3น/ก";
        case "22": //3น/ท";
        case "23": //3น/น";
        case "24": //3น/ถ";
        case "25": //3น.";
        case "26": //ต/3น.";
          return "yellow--text text--darken-3";
      }
    },
    getStatusText(p_Status) {
      const statusText = [
        "ปกติ",
        "ลบ",
        "แก้ไข",
        "อั้น",
        "ตีกลับ",
        "ถูก",
        "เลขเต็ม",
      ];
      return statusText[parseInt(p_Status)];
    },
    getStatusColor(p_Status) {
      const statusColor = [
        "grey--text text--darken-3",
        "red--text",
        "deep-orange--text text--accent-4",
        "deep-orange--text text--accent-4",
        "red--text",
        "amber--text text--darken-3",
        "red--text",
      ];
      return statusColor[parseInt(p_Status)];
    },
    getPriceSignColor(p_PriceText) {
      if (p_PriceText.indexOf(".") > 0) {
        return "red--text";
      } else if (p_PriceText.indexOf("x") > 0) {
        return "blue--text";
      } else {
        return "";
      }
    },
    //FOR KEY
    getTopColor() {
      //button
      let BetType = this.Input.BtType;

      //   console.log("getTopColor♥");
      //   console.log(BetType);
      if (
        BetType == "1" ||
        BetType == "3" ||
        BetType == "4" ||
        BetType == "6" ||
        BetType == "7" ||
        BetType == "8" ||
        BetType == "11" ||
        BetType == "12" ||
        BetType == "13" ||
        BetType == "15" ||
        BetType == "17" ||
        BetType == "18" ||
        BetType == "19" ||
        BetType == "20" ||
        BetType == "21" ||
        BetType == "22" ||
        BetType == "23" ||
        BetType == "24" ||
        BetType == "25" ||
        BetType == "26"
      )
        return "cyan lighten-1 white--text";
      return "grey";
    },
    getDownColor() {
      //button
      let BetType = this.Input.BtType;
      if (
        BetType == "2" ||
        BetType == "3" ||
        BetType == "5" ||
        BetType == "9" ||
        BetType == "10" ||
        BetType == "14" ||
        BetType == "16"
      )
        return "pink lighten-3 white--text";
      return "grey";
    },
    getNumKeyColor() {
      if (this.Stage == 0) return "blue lighten-5";
      return "";
    },
    getSignColor(Sign) {
      if (Sign == "ก") return "red--text";
      return "";
    },
    getP2KeyColor() {
      if (this.Stage == 1) return "blue lighten-5";
      return "";
    },
    ShowProcess(p_type, p_text) {
      switch (p_type) {
        case "process":
          break;
        case "error":
            alert(p_text);
          break;
      }
      this.Process_Text = p_text;
    },
    row_click: function (e, item) {
      if (this.Mode == 1) return;
      if (this.selectedId == item.id) {
        this.popup_menu(e, item);
      }

      this.selectedId = item.id;
      this.$set(item, "selected", true);
    },
    popup_menu: function (e, item) {
      e.preventDefault();
      this.$refs.PopupMenu.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu.Show(
          this.PageNumber,
          this.CusID,
          item.LineNumber,
          item.Status,
          item.id,
          this.getBetTypeName(item.BetTypeID),
          item.Number,
          item.PriceText
        );
        this.selectedId = item.id;
        //console.log(item);
      });
    },
  },
  data: () => ({
    // SYSTEM
    Mode: 0, //0 = Add , 1 = Edit
    CurrentScrollTop: 0,
    ///////////////
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    // EditLine

    // KEY
    Sending: false,

    selectedId: -1,
    PriceSumLine: 0,

    Input: {
      ShowLine: 1,
      id: 1,
      BtType: "1",
      Num: "",
      P1: "",
      P2: "",
      Sign: "",
    },

    LastLine: {
      ShowLine: 1,
      id: 1,
      BtType: "1",
      Num: "",
      P1: "",
      P2: "",
      Sign: "",
    },

    edit_data: [],

    Stage: 0,

    Process_Type: 0,
    Process_Text: "",
  }),
};
</script>

<style scoped>
.toolbar__items {
  flex-wrap: wrap;
}

.span_betline {
  width: 13%;
  /* border-bottom: 1px solid red; */
  font-size: 4vw;
  padding: 0;
  margin: 0;
  color: rgb(54, 54, 54);
}
.span_bettype {
  width: 15%;
  /* border-bottom: 1px solid red; */
  font-size: 6.5vw;
  padding-bottom: 5px;
  color: red;
}

.span_number {
  width: 18%;
  /* border-bottom: 1px solid red; */
  font-size: 5.3vw;
}
.span_equal {
  width: 3%;
  /* border-bottom: 1px solid red; */
  font-size: 5.3vw;
  padding-left: 2px;
}
.span_price1 {
  width: 23%;
  /* border-bottom: 1px solid red; */
  font-size: 5.3vw;
}
.span_sign {
  width: 3%;
  /* border-bottom: 1px solid red; */
  font-size: 5.3vw;
  padding-left: 2px;
}
.span_price2 {
  width: 23%;
  /* border-bottom: 1px solid red; */
  font-size: 5.3vw;
}
/* <v-card-title class="my-0 py-0"><span class="span_bettype">บน</span><span class="span_number">512</span><span class="span_equal">=</span><span class="span_price1">10</span><span class="span_sign">x</span><span class="span_price2">10</span></v-card-title> */

.keyboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(50px, auto);
  width: 100%;
  height: 80%;
  font-weight: bold;
  row-gap: 4px;
  column-gap: 4px;
}
.zero {
  grid-column: 1/3;
}

.multiply {
  grid-row-start: 2;
  grid-row-end: 4;
  grid-column-start: 4;
  grid-column-end: 5;
}

.equal {
  grid-row-start: 4;
  grid-row-end: 6;
  grid-column-start: 4;
  grid-column-end: 5;

  background-color: #e8e8e8 !important;
  color: #36485e;
  font-size: 7vw;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.2s linear;
  box-shadow: 7px 7px 15px #36485e36, -2px -2px 6px #ffffff;
}
.btn {
  background-color: #e8e8e8 !important;
  color: #36485e;
  font-size: 7vw;
  height: 100% !important;
  width: 100% !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.2s linear;
  box-shadow: 7px 7px 15px #36485e36, -2px -2px 6px #ffffff,
    inset 4px 4px 8px rgba(209, 217, 230, 0.35),
    inset -8px -8px 8px rgba(255, 255, 255, 0.3);
}

.btn_2 {
  background-color: #e8e8e8 !important;
  color: #36485e;
  font-size: 7vw;
  height: 100% !important;
  width: 100% !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.2s linear;
  box-shadow: 4px 4px 8px #36485e36, -2px -2px 6px #ffffff;
}

.btn_multiply {
  background-color: #e8e8e8 !important;
  color: #36485e;
  font-size: 7vw;
  height: 100% !important;
  width: 100% !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.2s linear;
  box-shadow: 4px 4px 8px #36485e36, -4px -4px 6px #ffffff;
}
.btnSwap {
  background-color: #e8e8e8 !important;
  color: #36485e;
  font-size: 9vw;
  height: 100% !important;
  width: 100% !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.2s linear;
  box-shadow: 4px 4px 8px #36485e36, -2px -2px 6px #ffffff;
}

::v-deep .btnSwap .v-btn__content {
  padding-bottom: 10px; /* เอา padding ของตัวปุ่มออก */
}

.btn_number {
  background-color: #e8e8e8 !important;
  color: #36485e;
  font-size: 7vw;
  height: 100% !important;
  width: 100% !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.2s linear;
  box-shadow: 4px 4px 8px #36485e36, -2px -2px 6px #ffffff,
    inset 4px 4px 8px rgba(209, 217, 230, 0.35),
    inset -8px -8px 8px rgba(255, 255, 255, 0.3);
}
/* table */
::v-deep table {
  background-color: #ffffff;
  /* table-layout: fixed; */
}
::v-deep tr:nth-child(odd) {
  background-color: #f5f4f4;
}
::v-deep table tbody tr td {
  font-size: 4.8vw !important;
  color: rgb(88, 88, 88);
  font-weight: bold;
}

.id_column {
  /* width: 6% !important;
  border-right: 1px solid rgb(190, 197, 255); */
  padding: 0 0;
}

.id_column_new {
  border-right: 1px solid rgb(236, 236, 236);
  color: rgb(82, 82, 82);
  width: 6% !important;
  padding: 0 0;
}

.bettype_column {
  width: 15% !important;
  /* background-color: blue; */
  /* padding: 0 5px !important; */
}
.betnumber_column {
  width: 15% !important;
  /* padding: 0 5px !important; */
}
.equal_column {
  width: 2% !important;
  /* background-color: grey; */
  /* padding: 0 10px !important; */
}
.price1_column {
  width: 20% !important;
  /* background-color: blue; */
  /* padding: 0 0px !important; */
}
.sign_column {
  width: 2% !important;
  /* padding: 0 8px !important; */
}
.price2_column {
  /* width: 20% !important; */
  /* background-color: blue; */
  /* padding: 0 0px !important; */
}
.status_column {
  /* width: 20% !important; */
  /* padding: 0 8px !important; */
}
::v-deep .border_class {
  border-right: 1px solid rgb(236, 236, 236);
}
.selected_class {
  background-color: pink !important;
  /* color: white !important; */
}
</style>
