<template>
  <v-container fluid grid-list-xs fill-height class="container">
    <v-layout
      row
      wrap
      class="layout header_bg"
      v-resize="updateDataTableHeight"
      fluid
    >
      <v-flex xs12 class="row_title">
        <v-layout
          row
          style="
            flex-flow: row;
            background-color: GhostWhite;
            padding-top: 10;
            font-weight: bold;
          "
          class="grey lighten-5"
        >
          <v-flex xs4 class="text-left ml-2 pl-0 mb-2">
            <span v-if="CusID!='001'">ชื่อลูกค้า</span> 
            <!-- <v-btn color="pink lighten-1" class="" outlined><v-icon left> mdi-arrow-left</v-icon> ดูโพย</v-btn> -->
          </v-flex>

          <v-flex
            xs8
            class="text-right mr-1 pink--text text--darken-1"
            v-if="CloseTime != ''"
          >
            ปิดรับ {{ getAbbrPeriodThai(CloseTime.split(" ")[0]) }} เวลา
            {{ CloseTime.split(" ")[1].split(":")[0] }}:{{
              CloseTime.split(" ")[1].split(":")[1]
            }}
            น.</v-flex
          >
        </v-layout>
        <v-divider class="pink lighten-1"></v-divider>
        <v-layout row style="flex-flow: row">
          <v-flex xs5 class="text-left ml-1 mt-0 pb-2">
            ใบที่ {{ PageNumber }}</v-flex
          >
          <v-flex xs7 class="text-right mt-0"
            >ลอย {{ add_comma(PriceLoy) }} รวม {{ add_comma(PriceSum) }}
          </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout
          row
          style="background-color: GhostWhite; flex-flow: row"
          class="py-1"
        >
          <v-btn
            id="menu-activator"
            color="blue lighten-2"
            dark
            @click="updateDataTableHeight()"
          >
            <v-icon left> mdi-percent-circle </v-icon>
            ดูเลขอั้น
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn color="purple lighten-2" dark @click="OnBack()">
            <v-icon left> mdi-check-circle </v-icon>
            เสร็จสิ้น
          </v-btn>
        </v-layout>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 class="row_content" ref="tableDiv">
        <Table
          ref="KeyTable"
          :tableheight="dataTableHeight"
          :CusID="CusID"
          :EditLine="EditLine"
          :PageNumber="parseInt(PageNumber)"
          :UpdateDT="UpdateDT"
        ></Table>
      </v-flex>
    </v-layout>
  </v-container>

  <!-- <div>ตาราง</div> -->

  <!-- <div>ยอดลอย</div> -->
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import Table from "@/components/Pages/KeyPage/Table";

export default {
  name: "Pages_Main",
  mixins: [globalFunctionMixin],
  components: {
    Table,
    //component name,
  },
  computed: {
    // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    PriceSum() {
      return this.$store.getters["page/PageData"].PriceSum;
    },
    PriceLoy() {
      return this.$store.getters["page/PageData"].PriceSumLoy;
    },
    UpdateDT() {
      return this.$store.getters["page/PageData"].UpdateDT;
    },
  },
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    this.PageNumber = this.$route.query.PageNumber;
    this.CusID = this.$route.query.CusID;
    this.EditLine = this.$route.query.EditLine;
    // this.updateDataTableHeight();
    setTimeout(() => {
      this.updateDataTableHeight();
    }, 1000);
    this.GetDataTable();
    // this.$refs.KeyTable.OnUpdateTable();
    
  },
  methods: {
    async GetDataTable() {
      let parameter = {
        to: "KeyPage",
        method: "get_all",
        PeriodDT: this.PeriodDT,
        CusID: this.CusID,
        PageNumber: parseInt(this.PageNumber),
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          this.$refs.KeyTable.OnUpdateTable();
          this.CloseTime = response.Period.CloseTime;
          this.PeriodStatus = parseInt(response.Period.Status);
          this.PeriodIsOpen = parseInt(response.Period.IsOpen);


          //   this.$router.push("/Dashboard");
          //   console.log(response);
          //   let obj1 = response.PageStatus.find((o) => o.status === "0");
          //   let obj2 = response.PageStatus.find((o) => o.status === "2");
          //   let obj3 = response.PageStatus.find((o) => o.status === "3");
          //   let obj4 = response.PageStatus.find((o) => o.status === "4");
          //   //   console.log(obj4.count);
          //   if (obj1 == null) this.WaitingSend_Count = 0;
          //   else this.WaitingSend_Count = parseInt(obj1.count);
          //   if (obj2 == null) this.SendBack_Count = 0;
          //   else this.SendBack_Count = parseInt(obj2.count);
          //   if (obj3 == null) this.Won_Count = 0;
          //   else this.Won_Count = parseInt(obj3.count);
          //   if (obj4 != null) this.Won_Count += parseInt(obj4.count);
          //   //response.Period
          //   this.CloseTime = response.Period.CloseTime;
          //   this.PeriodStatus = parseInt(response.Period.Status);
        },
        (error) => {
          //   switch (error) {
          //     case "PIN_INCORRECT":
          //       this.$refs.pincodeInput.resetPincode();
          //       this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
          //       break;
          //     default:
          //       alert(error);
          //       break;
          //   }
        }
      );
    },
    updateDataTableHeight() {
      this.dataTableHeight = this.$refs.tableDiv.clientHeight;
      console.log(this.dataTableHeight);
    },
    OnBack() {
      this.$router.push({
        path: "Page",
        query: {
          PageNumber: this.PageNumber,
          CusID: this.CusID,
        },
      });
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),
    CusID: "",
    PageNumber: 0,
    EditLine: "",

    CloseTime: "",
    PeriodStatus: 0,
    PeriodIsOpen: 0,

    dataTableHeight: 0,
  }),
};
</script>

<style scoped>
.container {
  background-color: red;
  flex-grow: 1;
  flex-shrink: 0;
}
.layout {
  flex-flow: column;
  padding-top: 10px;
}
.row_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1;
}

.row_content {
  background-color: whitesmoke;
  flex: 3 0px;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
}
.row_footer {
  background-color: lightcyan;
  flex: 0 1 40px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.header_bg {
  background-color: whitesmoke;
  color: black;
  font-size: 4.5vw;
  padding-top: 0;
}

.line {
  height: 2px;
  width: 100%;
  background-color: black;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable {
  cursor: pointer;
}
</style>
